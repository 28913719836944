import { CoreService } from "@/services";
import { useAuthStore } from "@/stores/auth";
import { defineStore } from "pinia";
import { ref } from "vue";

export interface UiResource {
  app_url: string;
  resource_name: string;
}

export const useFeatureUIsStore = defineStore("featureUIs", () => {
  const authStore = useAuthStore();

  const featureUiList = ref<UiResource[]>([]);
  const otherUiList = ref<UiResource[]>([
    {
      app_url: "https://app.govradar.net",
      resource_name: "govradar-frontend",
    },
    {
      app_url: "https://staging.govradar.net",
      resource_name: "govradar-frontend-staging",
    },
    {
      app_url: "https://dev.govradar.net",
      resource_name: "govradar-frontend-dev",
    },
  ]);

  if (authStore.isGovRadarMember) {
    CoreService.getStaticWebApps().then((list) => {
      featureUiList.value = list.filter(
        ({ resource_name }) => resource_name !== "main",
      );

      featureUiList.value.sort((a, b) =>
        a.resource_name.localeCompare(b.resource_name),
      );
    });
  }

  return {
    featureUiList,
    otherUiList,
  };
});

import { useServiceWorkerStore } from "@/stores/serviceWorker";
import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import assistant from "./assistant";
import chatGptSimulator from "./chatGptSimulator";
import playbooks from "./playbooks";
import serviceDescription from "./serviceDescription";
import spec from "./specs";
import user from "./user";
import userManual from "./userManual";

declare module "vue-router" {
  interface RouteMeta {
    scrollToTop?: boolean;
    disableSidebar?: boolean;
    disableHeaderBar?: boolean;
    disableHeaderBarActions?: boolean;
    disableStartBreadcrumb?: boolean;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    // legacy route from login button on www.govradar.net
    path: "/benutzer/login",
    name: "login",
    redirect: "/start",
  },
  {
    // welcome page handles the logic where to go
    path: "/",
    name: "root",
    redirect: "/start",
  },
  {
    path: "/start",
    name: "start",
    component: () => import("../views/homePage/HomePage.vue"),
  },
  ...serviceDescription,
  ...spec,
  ...user,
  ...assistant,
  ...playbooks,
  ...chatGptSimulator,
  ...userManual,
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () => import("../views/other/NotFound404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta.scrollToTop) return { top: 0 };
    if (savedPosition) return savedPosition;
  },
});

router.beforeEach((to) => {
  useServiceWorkerStore().throttledCheckForUpdates();
  if (to.query.next) return to.query.next as string;
});

export default router;

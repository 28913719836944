<template>
  <div class="min-h-[80vh]">
    <div class="flex flex-col items-start">
      <div class="flex items-center justify-between w-full pr-8 text-govdark">
        <div class="flex items-center gap-2">
          <MegaphoneIcon class="size-8" />
          <h1 class="text-3xl font-bold">Neueste Produkt-Updates</h1>
        </div>
        <div v-if="announcements.length > 1" class="flex items-center gap-2">
          <ButtonNormal
            :disabled="currentIndex === 0"
            kind="secondary"
            @click="currentIndex--"
          >
            Vorherige
          </ButtonNormal>
          <ButtonNormal
            :disabled="currentIndex === announcements.length - 1"
            kind="action"
            @click="currentIndex++"
          >
            Nächste
          </ButtonNormal>
        </div>
      </div>
      <div class="flex gap-3 my-4 text-sm">
        <ButtonToggle v-model="doNotShowAgain" size="sm" />
        Nicht mehr anzeigen
      </div>
      <ReleaseAnnouncementCard
        v-bind="announcements[currentIndex]"
        class="mt-4 w-full"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import ButtonNormal from "@govradar/vue-components/components/ButtonNormal.vue";
import { onBeforeUnmount, ref } from "vue";
import ReleaseAnnouncementCard from "./ReleaseAnnouncementCard.vue";
import { MegaphoneIcon } from "@heroicons/vue/24/solid";
import { storeToRefs } from "pinia";
import { useReleaseAnnouncementStore } from "@/stores/announcements";
import ButtonToggle from "../ButtonToggle.vue";

const store = useReleaseAnnouncementStore();
const { announcements } = storeToRefs(store);
const { markAsViewed } = store;

const currentIndex = ref(0);

const doNotShowAgain = ref(false);
onBeforeUnmount(() => {
  if (doNotShowAgain.value) {
    markAsViewed();
  }
});
</script>

import { ResponseType } from "axios";
import { createAndSetupAxios } from "@/helpers/setupAxios";
import {
  BasePlaybook,
  PlaybookTemplate,
  PlaybookFlatInfo,
  FullPlaybook,
  PlaybookFlatCreate,
  PlaybookDataFlat,
  VerdeMetaData,
  PlaybookTemplateStateUpdate,
  PlaybookTemplateHistory,
  PlaybookStateUpdate,
  RevisionData,
  HTMLDocument,
  PlaybookMetaData,
} from "@/types/playbook";
import { User } from "@/types/user";
import downloadFile from "@/helpers/downloadFile";

const $axios = createAndSetupAxios(import.meta.env.VITE_PLAYBOOK_ROUTE);

class PlaybookService {
  getAllPlaybooks(): Promise<PlaybookFlatInfo[]> {
    return $axios.get(`v1/playbooks/`).then((response) => response.data);
  }

  getUserPlaybook(userPlaybookId: string): Promise<FullPlaybook> {
    return $axios
      .get(`v1/playbooks/${userPlaybookId}/`)
      .then((response) => response.data);
  }

  addUserPlaybook(playbook: PlaybookFlatCreate): Promise<FullPlaybook> {
    return $axios
      .post(`v1/playbooks/`, playbook)
      .then((response) => response.data);
  }

  copyTemplateToPlaybook(data): Promise<PlaybookFlatInfo> {
    return $axios
      .post(`v1/playbooks/use_template/`, data)
      .then((response) => response.data);
  }

  deleteUserPlaybook(userPlaybook: PlaybookFlatInfo): Promise<FullPlaybook> {
    return $axios
      .delete(`v1/playbooks/${userPlaybook.id}/`)
      .then((response) => response.data);
  }

  updateUserPlaybook(
    userPlaybookId: string,
    playbook: BasePlaybook,
  ): Promise<FullPlaybook> {
    return $axios
      .patch(`v1/playbooks/${userPlaybookId}/`, playbook)
      .then((response) => response.data);
  }

  async updatePlaybookFavorite(
    id: string,
    favorite: boolean,
  ): Promise<PlaybookTemplate> {
    return $axios
      .patch(`v1/playbooks/${id}/update_favorite/?favorite=${favorite}`)
      .then((response) => response.data);
  }

  getAllPlaybookTemplates(): Promise<PlaybookTemplate[]> {
    return $axios.get(`v1/templates/`).then((response) => response.data);
  }

  getPlaybookTemplate(templateId: string): Promise<PlaybookDataFlat> {
    return $axios
      .get(`v1/templates/${templateId}/`)
      .then((response) => response.data);
  }

  createNewTemplate(template: BasePlaybook): Promise<string> {
    return $axios
      .post(`v1/templates/`, template)
      .then((response) => response.data);
  }

  deleteTemplate(template: PlaybookTemplate): Promise<string> {
    return $axios
      .delete(`v1/templates/${template.id}/`)
      .then((response) => response.data);
  }

  updateTemplate(
    templateid: string,
    newtemplate: BasePlaybook,
  ): Promise<string> {
    return $axios
      .patch(`v1/templates/${templateid}/`, newtemplate)
      .then((response) => response.data);
  }

  updateTemplateMetaData(
    templateid: string,
    data: VerdeMetaData,
  ): Promise<string> {
    return $axios
      .patch(`v1/templates/${templateid}/change_meta_data/`, data)
      .then((response) => response.data);
  }

  updatePlaybookMetaData(
    playbookid: string,
    data: VerdeMetaData,
  ): Promise<string> {
    return $axios
      .patch(`v1/playbooks/${playbookid}/change_meta_data/`, data)
      .then((response) => response.data);
  }

  updatePlaybookTemplateState(
    templateId: string,
    state: PlaybookTemplateStateUpdate,
  ): Promise<string> {
    return $axios
      .patch(`v1/templates/${templateId}/change_state/`, state)
      .then((response) => response.data);
  }

  updatePlaybookState(
    playbookId: string,
    state: PlaybookStateUpdate,
  ): Promise<string> {
    return $axios
      .patch(`v1/playbooks/${playbookId}/change_state/`, state)
      .then((response) => response.data);
  }

  getAllApprovedOrgTemplates(orgId: string): Promise<PlaybookTemplate[]> {
    return $axios
      .get(`v1/templates/org/${orgId}/`)
      .then((response) => response.data);
  }

  getPlaybookTemplateHistory(id: string): Promise<PlaybookTemplateHistory[]> {
    return $axios
      .get(`v1/templates/${id}/history/`)
      .then((response) => response.data);
  }

  getPlaybookHistory(id: string): Promise<PlaybookTemplateHistory[]> {
    return $axios
      .get(`v1/playbooks/${id}/history/`)
      .then((response) => response.data);
  }

  async copyPlaybookTemplate(data: {
    id: string;
    title: string;
  }): Promise<string> {
    const { id, title } = data;
    const copy = { new_title: title };

    return $axios
      .post(`v1/templates/${id}/copy/`, copy)
      .then((response) => response.data);
  }

  async copyPlaybook(data: { id: string; title: string }): Promise<string> {
    const { id, title } = data;
    const copy = { new_title: title };

    return $axios
      .post(`v1/playbooks/${id}/copy/`, copy)
      .then((response) => response.data);
  }

  async updatePlaybookTemplateFavorite(
    id: string,
    favorite: boolean,
  ): Promise<PlaybookTemplate> {
    return $axios
      .patch(`v1/templates/${id}/update_favorite/?favorite=${favorite}`)
      .then((response) => response.data);
  }

  async sharePlaybookTemplate(
    playbookTemplateId: string,
    newEditors: User[],
  ): Promise<PlaybookTemplate> {
    return $axios
      .patch(`v1/templates/${playbookTemplateId}/update_editors/`, newEditors)
      .then((response) => response.data);
  }

  async sharePlaybook(
    playbookId: string,
    newEditors: User[],
  ): Promise<PlaybookTemplate> {
    return $axios
      .patch(`v1/playbooks/${playbookId}/update_editors/`, newEditors)
      .then((response) => response.data);
  }

  async createPlaybookRevision(
    playbookId: string,
    docx_file: Blob,
    revision_data: Omit<RevisionData, "id">,
  ): Promise<PlaybookFlatInfo> {
    return $axios
      .postForm(`v1/playbooks/${playbookId}/revision/`, {
        docx_file,
        revision_data: JSON.stringify(revision_data),
      })
      .then((response) => response.data);
  }

  async updateRevision(
    playbookId: string,
    revisionId: string,
    note,
  ): Promise<PlaybookMetaData> {
    return await $axios
      .patch(`v1/playbooks/${playbookId}/revision/${revisionId}/`, note)
      .then((response) => response.data);
  }

  async downloadRevisionWithType(
    revision: RevisionData,
    requestType: string,
    responseType: ResponseType,
    fileExtension: string,
  ): Promise<void> {
    const response = await $axios.get(
      `v1/playbooks/${revision.playbook_id}/revision/${revision.id}/${requestType}/`,
      {
        responseType,
      },
    );

    const title = `${revision.title}_Revision_${revision.version}_${new Date(
      revision.created_at,
    ).toLocaleString()}`
      .replaceAll("/", "_")
      .replaceAll(":", "_");

    downloadFile(response, `${title}${fileExtension}`);
  }

  async previewRevision(
    revision: RevisionData,
    fileExtension: string,
    fileName?: string,
  ): Promise<File> {
    const response = await $axios.get(
      `v1/playbooks/${revision.playbook_id}/revision/${revision.id}/${fileExtension}/`,
      {
        responseType: "blob",
      },
    );
    return new File(
      [response.data],
      `${fileName ?? revision.title}.${fileExtension}`,
    );
  }

  async downloadRevisionDocx(revision: RevisionData): Promise<void> {
    return this.downloadRevisionWithType(revision, "docx", "blob", ".docx");
  }

  async downloadRevisionPdf(revision: RevisionData): Promise<void> {
    return this.downloadRevisionWithType(revision, "pdf", "blob", ".pdf");
  }

  async turnDocumentHtmlIntoPdf(
    playbookId: string,
    htmlData: HTMLDocument,
    download: boolean,
    documentTitle?: string,
  ) {
    const response = await $axios.post(
      `v1/playbooks/${playbookId}/pdf/`,
      htmlData,
      {
        responseType: "blob",
      },
    );
    if (download) {
      const title = `${documentTitle}_Entwurf_${new Date().toLocaleString()}`
        .replaceAll("/", "_")
        .replaceAll(":", "_");

      downloadFile(response, `${title}.pdf`);
    } else {
      return new File([response.data], `document.pdf`);
    }
  }
}

export default new PlaybookService();

import { Analytics } from "..";
import { EventDataMap, EventName, getEventCategory } from "..";

export default class MatomoAnalytics implements Analytics {
  trackEvent<E extends EventName>(
    eventName: E,
    payload?: EventDataMap[E],
  ): void {
    try {
      const category = getEventCategory(eventName);
      const args: unknown[] = ["trackEvent", category, eventName];
      const customData = payload ? JSON.stringify(payload) : null;
      if (customData && customData.length < 4056) {
        args.push(customData);
        args.push(0);
      }
      window._paq.push(args);
    } catch (error) {
      console.error("Error tracking event", error);
    }
  }

  enableAutoTracking(): void {
    try {
      this.trackButtonClicks();
    } catch (error) {
      console.error("Error enabling auto tracking", error);
    }
  }

  private trackButtonClicks(): void {
    window.addEventListener("click", (event) => {
      //Only track clicks on buttons
      if (!(event.target instanceof HTMLElement)) {
        return;
      }
      const button = event.target.closest("button");
      if (!button) {
        return;
      }
      const title =
        button.getAttribute("title") ?? button.textContent ?? "unknown";
      this.trackEvent("button_click", { title });
    });
  }
}

<script setup lang="ts">
import PopoverMenu from "@/components/PopoverMenu.vue";
import { BookOpenIcon } from "@heroicons/vue/24/outline";
import UserManualOverview from "@/views/userManual/UserManualOverview.vue";
import { useFeatureStore } from "@/stores/feature";
import TooltipWrapper from "@/components/TooltipWrapper.vue";
import { useRouter } from "vue-router";
import NavBarButton from "@/components/NavBarButton.vue";

const featureStore = useFeatureStore();
const router = useRouter();

function openManual() {
  const routeData = router.resolve({ name: "user-manual-overview" });
  window.open(routeData.href, "_blank");
}
</script>

<template>
  <PopoverMenu
    v-if="featureStore.flags.user_manual_popover"
    :icon="BookOpenIcon"
    aria-label="Hilfe Popover"
  >
    <UserManualOverview in-popover />
  </PopoverMenu>
  <TooltipWrapper
    v-else
    placement="bottom-end"
    description="Anleitungen"
    class="my-auto"
  >
    <NavBarButton
      :icon="BookOpenIcon"
      aria-label="Anleitungen"
      @click="openManual"
    />
  </TooltipWrapper>
</template>

import { ref } from "vue";
import { defineStore } from "pinia";
import { useRegisterSW } from "virtual:pwa-register/vue";
import { whenever } from "@vueuse/core";
import { useAlertStore } from "@/stores/alert";
import axios from "axios";
import { throttle } from "es-toolkit";

export const useServiceWorkerStore = defineStore("serviceWorker", () => {
  let sw: ServiceWorkerRegistration | undefined;
  let swUrl = "";

  const lastUpdateCheck = ref<Date>();
  const updating = ref(false);

  const { needRefresh, offlineReady, updateServiceWorker } = useRegisterSW({
    async onRegisteredSW(activeSW, registrationOptions) {
      sw = registrationOptions;
      swUrl = activeSW;

      // event listener for update starting
      sw?.addEventListener("updatefound", () => (updating.value = true));

      // reload page if service worker needs refresh
      if (needRefresh.value) await updateServiceWorker();
    },
    onRegisterError(error) {
      console.error("Failed to register service Worker", error);
    },
  });

  whenever(needRefresh, () => {
    updating.value = false;
    useAlertStore().createAlert({
      type: "update",
      message: "Eine neue Version der GovRadar App ist verfügbar.",
      actions: [{ label: "Aktualisieren", handler: updateServiceWorker }],
    });
  });

  whenever(offlineReady, () => (updating.value = false));

  async function checkForUpdates() {
    // return if service worker is not installed
    if (!sw) return;
    // return if service worker is currently installing
    if (!(!sw.installing && navigator)) return;
    // return if offline
    if ("connection" in navigator && !navigator.onLine) return;

    try {
      // check if sw is available on server
      const response = await axios.head(swUrl, {
        headers: {
          cache: "no-store",
          "cache-control": "no-cache",
        },
      });

      if (response?.status === 200) await sw.update();
      lastUpdateCheck.value = new Date();
    } catch {
      console.error("Failed to update service worker");
    }
  }

  const throttledCheckForUpdates = throttle(checkForUpdates, 30_000);

  return {
    needRefresh,
    offlineReady,
    lastUpdateCheck,
    updating,
    checkForUpdates,
    throttledCheckForUpdates,
    updateServiceWorker,
  };
});

<template>
  <SwitchGroup
    as="div"
    class="flex items-center select-none"
    :class="{
      'gap-x-2': size === 'sm',
      'gap-x-3': size === 'md',
    }"
  >
    <Switch
      v-model="enabled"
      class="relative inline-flex flex-shrink-0 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-govdark focus-visible:ring-offset-2 focus-visible:ring-offset-gray-100"
      :class="{
        'bg-gray-200 enabled:bg-govdark': enabled,
        'bg-gray-200 enabled:bg-gray-300': !enabled,
        'h-4 w-8': size === 'sm',
        'h-6 w-11': size === 'md',
        '!cursor-default': disabled,
      }"
      :disabled="disabled"
      @update:model-value="(value) => emit('click', value)"
    >
      <span
        aria-hidden="true"
        class="pointer-events-none inline-block rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        :class="{
          'translate-x-0': !enabled,
          'h-3 w-3': size === 'sm',
          'translate-x-4': enabled && size === 'sm',
          'h-5 w-5': size === 'md',
          'translate-x-5': enabled && size === 'md',
        }"
      />
    </Switch>

    <SwitchLabel
      class="transition ease-in-out duration-200"
      :class="[labelClass, !label && 'sr-only', disabled && 'opacity-60']"
    >
      {{ label || srLabel }}
    </SwitchLabel>
  </SwitchGroup>
</template>

<script setup lang="ts">
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { useVModel } from "@vueuse/core";

const props = withDefaults(
  defineProps<{
    label?: string;
    srLabel?: string;
    labelClass?: string;
    disabled?: boolean;
    size?: "sm" | "md";

    // eslint-disable-next-line vue/no-unused-properties
    modelValue?: boolean;
  }>(),
  {
    label: "",
    srLabel: "",
    labelClass: "text-indigo-700 font-medium text-sm",
    size: "md",
    modelValue: false,
  },
);

const emit = defineEmits<{
  (eventName: "update:modelValue", value: boolean): void;
  (eventName: "click", value: boolean): void;
}>();

const enabled = useVModel(props, "modelValue", emit);
</script>

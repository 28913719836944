// PROD
export const UI_SUBDOMAIN_PROD = "app.govradar.net";
export const UI_SUBDOMAIN_STAGING = "staging.govradar.net";
export const AUTH_URL_PROD = "https://auth.govradar.net/";
// DEV
export const UI_SUBDOMAIN_DEV = "dev.govradar.net";
export const AUTH_URL_DEV = "https://auth-dev.govradar.net/";
// LOCAL DEV WITH REVERSE PROXY
export const UI_LOCAL_REVERSE_PROXY = "app.localhost";
export const AUTH_URL_LOCAL_REVERSE_PROXY = "https://auth.localhost/"; // = api.localhost/services/
// GATEWAYS
const API_GATEWAY_ROUTE = "/api/services/";
export const API_GATEWAY_URL_PROD = "https://api.govradar.net/services/"; // = app.govradar.net/api/services/
export const API_GATEWAY_URL_DEV = "https://api-dev.govradar.net/services/"; // = dev.govradar.net/api/services/
export const API_GATEWAY_LOCAL_REVERSE_PROXY =
  "https://api.localhost/services/"; // = app.localhost/api/services/
// OTHER / DYNAMIC CONFIGS
// const KEYCLOAK_PORT = "8443";
// const API_GATEWAY_PORT = "5000";

export const isFullURL = (input: string): boolean =>
  /^https?:\/\//i.test(input);

export const trimSlashesStart = (value: string): string =>
  value.replace(/^\/+/, "");

export const trimSlashesEnd = (value: string): string =>
  value.replace(/\/+$/, "");

export const trimSlashes = (value: string): string =>
  // value.replaceAll(/(^\/+)|(\/+$)/g, "");
  trimSlashesStart(trimSlashesEnd(value));

// if running outside a brwoser e.g. when running tests and/or serving from a NodeJS server use n/a
const HOSTNAME =
  typeof window !== "undefined" ? window.location.hostname : "n/a";
const PROTOCOL =
  typeof window !== "undefined" ? window.location.protocol : "n/a";
const HOST = PROTOCOL + "//" + HOSTNAME;

export function getKeycloakURL(): string {
  // if specified in .env file explicitly, this always takes precedence
  if (import.meta.env.VITE_AUTH_URL) {
    return trimSlashesEnd(import.meta.env.VITE_AUTH_URL);
  }
  // otherwise choose URL dynamically, based on HOSTNAME; evaluating multiple cases using "fall-through"
  switch (HOSTNAME) {
    case UI_SUBDOMAIN_PROD: // app.govradar.net --> auth.govradar.net
    case UI_SUBDOMAIN_STAGING: // staging.govradar.net --> auth.govradar.net
      return trimSlashesEnd(AUTH_URL_PROD);
    case UI_LOCAL_REVERSE_PROXY: // app.localhost --> auth.localhost
      return trimSlashesEnd(AUTH_URL_LOCAL_REVERSE_PROXY);
    default: // dev.govradar.net, Feature UIs & local dev --> auth-dev.govradar.net
      return trimSlashesEnd(
        import.meta.env.MODE === "development" ? AUTH_URL_DEV : AUTH_URL_PROD,
      );
    // default:                                   // all other, e.g. VM IP addresses  --> "xxxx.xxxx.xxxx.xxxx:8443/"
    //   return trimSlashesEnd(HOST + ":" + KEYCLOAK_PORT);
  }
}

export function getGatewayURL(): string {
  // if specified in .env file explicitly, this always takes precedence
  if (import.meta.env.VITE_API_GATEWAY_URL) {
    return trimSlashesEnd(import.meta.env.VITE_API_GATEWAY_URL);
  }
  // otherwise choose URL dynamically, based on HOSTNAME; evaluating multiple cases using "fall-through"
  switch (HOSTNAME) {
    case UI_SUBDOMAIN_PROD: // app.govradar.net --> app.govradar.net/api/services/
    case UI_SUBDOMAIN_STAGING: // staging.govradar.net --> staging.govradar.net/api/services/
    case UI_SUBDOMAIN_DEV: // dev.govradar.net --> dev.govradar.net/api/services/
    case UI_LOCAL_REVERSE_PROXY: // app.localhost --> app.localhost/api/services/ (or api.localhost)
      return trimSlashesEnd(HOST + API_GATEWAY_ROUTE);
    default: // Feature UIs & local dev --> api-dev.govradar.net (or dev.govradar.net/api/services/)
      return trimSlashesEnd(
        import.meta.env.MODE === "development"
          ? API_GATEWAY_URL_DEV
          : API_GATEWAY_URL_PROD,
      );
    // default:                                     // all other, e.g. VM IP addresses --> // "xxxx.xxxx.xxxx.xxxx:5000/services/"
    //   return trimSlashesEnd(HOST+":"+API_GATEWAY_PORT+"/services/");
  }
}

/**
 * @param input gateway route or absolute URL
 * @returns normalized route
 */
export function buildURL(input: string): string {
  if (isFullURL(input)) {
    return input;
  }

  const apiGatewayURL = getGatewayURL();
  const normalizedPath = trimSlashes(input);

  return `${apiGatewayURL}/${normalizedPath}/`;
}

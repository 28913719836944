<template>
  <HeadingTop heading="Feature Flags">
    <p>Geben Sie an welche Feature Flags aktiv sein sollen.</p>
  </HeadingTop>
  <ButtonToggle
    v-for="(value, key) in featureStore.flags"
    :key="key"
    class="mt-2 text-black text-lg"
    :label="key ?? 'unknown'"
    :model-value="value"
    @update:model-value="featureStore.flags[key] = $event"
  />
  <ButtonNormal
    class="mt-4"
    kind="secondary"
    @click="featureStore.resetFlags()"
  >
    Load Default Feature Flags
  </ButtonNormal>
  <ButtonNormal
    class="mt-4 ml-2"
    kind="secondary"
    @click="featureStore.saveFlags()"
  >
    Save Feature Flags
  </ButtonNormal>
</template>

<script setup lang="ts">
import HeadingTop from "@/components/HeadingTop.vue";
import ButtonToggle from "@/components/ButtonToggle.vue";
import { ButtonNormal } from "@govradar/vue-components";
import { useFeatureStore } from "@/stores/feature";

const featureStore = useFeatureStore();
</script>

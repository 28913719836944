import { RouteRecordRaw } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { h } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/benutzer/logout",
    name: "user-logout",
    component: { render: () => h("div") },
    beforeEnter: () =>
      useAuthStore().logout({ redirectUri: window.location.origin }),
  },
  {
    path: "/benutzer/einstellungen",
    name: "user-settings",
    redirect: { name: "user-settings-account" },
    component: () => import("../views/user/Dashboard.vue"),
    children: [
      {
        path: "account",
        name: "user-settings-account",
        component: () => import("../views/user/AccountChange.vue"),
      },
      {
        path: "passwort",
        name: "user-settings-password",
        component: () => import("../views/user/PasswordChange.vue"),
      },
      {
        path: "nutzer-hinzufuegen",
        name: "user-settings-add-user",
        component: () => import("../views/user/AddUser.vue"),
      },
      {
        path: "nutzer-verwalten",
        name: "user-settings-org-users",
        component: () => import("../views/user/OrgUsers.vue"),
      },
      {
        path: "zwei-faktor-authentifizierung",
        name: "user-settings-2fa",
        component: () => import("../views/user/TwoFactorAuth.vue"),
      },
      {
        path: "feature-flags",
        name: "feature-flags",
        component: () => import("../views/user/SelectFeatureFlags.vue"),
      },
      {
        path: "lizenzen-verwalten",
        name: "user-settings-licences",
        component: () => import("../views/user/LicenseManagement.vue"),
      },
    ],
  },
];

export default routes;

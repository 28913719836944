import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/vergabeunterlagen",
    name: "vergabeunterlagen",
    component: () => import("../views/assistant/TendersMain.vue"),
  },
];

export default routes;

<template>
  <component :is="is" :class="getHeadingClass">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    is?: string;
    size?: string;
  }>(),
  {
    is: "h1",
    size: "",
  },
);

const headingSize = computed(() => {
  return props.size || props.is;
});

const getHeadingClass = computed(() => {
  if (props.is === "h1") {
    return `${getHeadingSize.value} font-bold text-gray-800`;
  }

  if (props.is === "h2") {
    return `${getHeadingSize.value} font-bold text-gray-800`;
  }

  return `${getHeadingSize.value} font-bold text-gray-800`;
});

const getHeadingSize = computed(() => {
  if (headingSize.value === "h1") {
    return "text-3xl";
  }

  if (headingSize.value === "h2") {
    return "text-2xl";
  }

  return "text-xl";
});
</script>

import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/leistungsbeschreibungen",
    name: "service-description-list",
    component: () =>
      import("../views/serviceDescription/ServiceDescriptionList.vue"),
  },
  {
    path: "/leistungsbeschreibungen/:serviceDescription",
    name: "service-description-detail",
    component: () =>
      import("../views/serviceDescription/ServiceDescriptionDetail.vue"),
  },

  // Legacy routes
  {
    path: "/leistungsverzeichnisse",
    name: "legacy-service-description-list",
    redirect() {
      return {
        name: "service-description-list",
      };
    },
  },
  {
    path: "/leistungsverzeichnisse/:serviceDescription",
    name: "legacy-service-description-detail",
    redirect(to) {
      return {
        name: "service-description-detail",
        params: {
          serviceDescription: to.params.serviceDescription,
        },
      };
    },
  },
  {
    path: "/leistungsverzeichnisse/:serviceDescription/details/:type/:id/:prefix",
    name: "legacy-service-description-element-detail",
    redirect(to) {
      return {
        name: "service-description-detail",
        params: {
          serviceDescription: to.params.serviceDescription,
        },
      };
    },
  },
];

export default routes;

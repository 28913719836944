import { isEqual } from "es-toolkit";
import type { DeepPartial } from "@/types/shared";

/** Returns only shallow changed entities between new and old item */
export function diff<T extends object>(newItem: T, oldItem?: T): Partial<T> {
  if (oldItem === undefined || oldItem === null) return newItem;
  const difference: Partial<T> = {};
  for (const key of Object.keys(newItem) as (keyof T)[]) {
    if (!isEqual(oldItem[key], newItem[key])) {
      difference[key] = newItem[key];
    }
  }
  return difference;
}

/** Returns only deep changed entities between new and old item */
export function deepDiff<T extends object>(
  newItem: T,
  oldItem?: T,
): DeepPartial<T> {
  if (oldItem === undefined || oldItem === null) return newItem;
  const difference: Partial<T> = {};
  for (const key of Object.keys(newItem) as (keyof T)[]) {
    if (typeof newItem[key] === "object" && newItem[key] && oldItem[key]) {
      const deepDifference = deepDiff(newItem[key], oldItem[key]);
      if (Object.keys(deepDifference).length > 0) {
        difference[key] = deepDifference as T[keyof T];
      }
    } else if (!isEqual(oldItem[key], newItem[key])) {
      difference[key] = newItem[key];
    }
  }
  return difference;
}

export function hasDiff<T extends object>(newItem: T, oldItem?: T): boolean {
  return Object.keys(diff(newItem, oldItem)).length > 0;
}

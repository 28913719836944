import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/verde/contracts",
    name: "verde",
    component: () => import("../views/verde/PlaybookOverview.vue"),
  },
  {
    path: "/verde/contracts/open/:template",
    name: "verde-open-template",
    component: () => import("../views/verde/VerdeOpenPlaybook.vue"),
  },
  {
    path: "/verde/templates/createtemplate/:template?",
    name: "verde-create-template",
    component: () => import("../views/verde/VerdeCreateTemplate.vue"),
  },
  {
    path: "/verde/templates/owntemplates",
    name: "verde-own-templates",
    component: () => import("../views/verde/VerdeOwnTemplates.vue"),
  },
];

export default routes;

<script setup lang="ts">
import { onMounted } from "vue";
import { useUserManualStore } from "@/stores/userManuals";
import { ChevronRightIcon, BookOpenIcon } from "@heroicons/vue/20/solid";
import HeadingBar from "@/components/HeadingBar.vue";
import useBreadcrumbs from "@/composables/useBreadcrumbs";

defineProps<{
  inPopover?: boolean;
}>();

const userManualStore = useUserManualStore();

onMounted(async () => {
  await userManualStore.initializeManuals();
});

useBreadcrumbs([
  {
    text: "Anleitungen",
    to: { name: "user-manual-overview" },
    icon: BookOpenIcon,
  },
]);
</script>

<template>
  <div class="@container/main max-w-6xl mx-auto">
    <HeadingBar v-if="!inPopover" is-component="h1" heading="Anleitungen" />
    <div
      class="grid @5xl/main:grid-cols-3 @xl/main:grid-cols-2 grid-cols-1 gap-4"
    >
      <div
        v-for="group of userManualStore.groupedUserManuals"
        :key="group.name"
        class="flex flex-col h-full bg-white text-products-blue-dark overflow-hidden"
        :class="{
          'p-5 shadow-product-feature rounded-lg': !inPopover,
          'row-span-2 @lg/main:row-span-2': group.size === 2,
        }"
      >
        <div class="flex items-center gap-x-2">
          <component
            :is="group.icon"
            class="h-7 w-7 text-products-blue-light"
            aria-hidden="true"
          />
          <h2 class="grow text-2xl font-bold">{{ group.name }}</h2>
        </div>

        <hr v-if="!inPopover" class="mt-3 border-govdark-200" />

        <div v-for="section of group.sections" :key="section.name" class="mt-3">
          <h3 class="text-xl font-bold">{{ section.name }}</h3>
          <ul class="mt-1 mb-2 grow flex flex-col">
            <li
              v-for="manual of section.manuals"
              :key="manual.id"
              class="relative hover:bg-gray-100 py-1.5 pl-1 rounded-md group"
            >
              <router-link
                :to="{ name: 'user-manual', params: { id: manual.id } }"
                class="flex text-black hover:text-black text-medium"
                :target="inPopover ? '_blank' : undefined"
              >
                <ChevronRightIcon
                  class="size-6 text-govdark flex-shrink-0 -translate-x-1 group-hover:translate-x-0 transition"
                />
                {{ manual.name }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

import { useAlertStore } from "../stores/alert";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";

export default () => {
  const alertStore = useAlertStore();
  const authStore = useAuthStore();
  const { inactivityTimeout, inactivityWarningThreshold, logout } = authStore;
  const { lastActivity } = storeToRefs(authStore);

  let inactivityTimer: ReturnType<typeof setTimeout> | null = null;

  const activityEvents = [
    "mousemove",
    "mousedown",
    "keydown",
    "touchstart",
    "scroll",
  ];

  activityEvents.forEach((event) => {
    window.addEventListener(event, resetInactivity);
  });

  function resetInactivity() {
    if (inactivityTimer) clearTimeout(inactivityTimer);
    lastActivity.value = Date.now();
    alertStore.removeAlert({ id: alertId });
    alertId = undefined;
    inactivityTimer = setTimeout(handleInactivity, inactivityTimeout);
  }

  const handleInactivity = () => {
    logout();
  };

  setInterval(displayWarning, 500);

  let alertId: string | undefined = undefined;

  function displayWarning() {
    const timeTillLogout =
      inactivityTimeout - (Date.now() - lastActivity.value);

    if (timeTillLogout < inactivityWarningThreshold && !alertId) {
      alertId = alertStore.createAlert({
        message:
          "Sie werden in Kürze wegen Inaktivität automatisch abgemeldet.",
        type: "info",
      });
    }
  }
};

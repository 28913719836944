<template>
  <div class="flex flex-col items-start bg-gray-100 p-4 rounded-md">
    <span class="text-sm text-gray-500">
      {{ new Date(active_since).toLocaleDateString() }}
    </span>
    <span class="text-3xl font-bold pb-6">
      {{ title }}
    </span>

    <shadow-root :adopted-style-sheets="[descriptionStyleSheet]">
      <p class="pb-6" v-html="description"></p>
    </shadow-root>
    <div v-if="loadingMedia" class="w-full flex justify-center">
      <CircleSpinner class="size-6" />
    </div>
    <div
      v-else-if="sources.length > 0"
      class="flex flex-col items-center w-full mx-auto"
    >
      <div class="w-full flex items-center justify-between">
        <ButtonNormal kind="light" :disabled="currentMedia === 0">
          <ArrowLeftIcon class="h-6 w-6" @click="currentMedia--" />
        </ButtonNormal>
        <ButtonNormal
          kind="light"
          :disabled="currentMedia === sources.length - 1"
        >
          <ArrowRightIcon class="h-6 w-6" @click="currentMedia++" />
        </ButtonNormal>
      </div>
      <div class="bg-gray-500 flex items-center justify-center">
        <img
          :src="sources[currentMedia].src"
          :alt="sources[currentMedia].alt"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/vue/24/outline";
import { computed, ref, watch } from "vue";
import CircleSpinner from "../CircleSpinner.vue";
import ButtonNormal from "@govradar/vue-components/components/ButtonNormal.vue";
import DescriptionStyle from "@/release-announcement.css?inline";
import { storeToRefs } from "pinia";
import { useReleaseAnnouncementStore } from "@/stores/announcements";
import { ReleaseAnnouncement } from "@/services/iam";

const descriptionStyleSheet = computed<CSSStyleSheet>(() => {
  const styleSheet = new CSSStyleSheet();
  styleSheet.replaceSync(DescriptionStyle);

  return styleSheet;
});

const { id } = defineProps<ReleaseAnnouncement>();
const store = useReleaseAnnouncementStore();
const { loadingMedia, mediaSources } = storeToRefs(store);
const { loadMedia } = store;

watch(
  () => id,
  (v) => {
    loadMedia(v);
    currentMedia.value = 0;
  },
);

const sources = computed(() =>
  (mediaSources.value[id] ?? []).map((src, i) => ({
    src,
    alt: `Media ${i + 1}`,
  })),
);

const currentMedia = ref(0);
</script>

import { createAndSetupAxios } from "@/helpers/setupAxios";
import { TemplateSpec } from "@/types/criterion";

const $axios = createAndSetupAxios(import.meta.env.VITE_TEMPLATES_ROUTE);

class TemplatesService {
  async getTemplates(): Promise<TemplateSpec[]> {
    const response = await $axios.get(`templates/`);
    return response.data;
  }
}

export default new TemplatesService();

import { useAlertStore } from "@/stores/alert";
import type {
  GovRadarKeycloakTokenParsed,
  UserLicenseType,
} from "@/types/keycloak";
import Keycloak, { KeycloakLogoutOptions } from "keycloak-js";
import { defineStore } from "pinia";
import { computed, ref, shallowRef, triggerRef } from "vue";

const govRadarOrgId = "0113a255-6df7-4fc5-8518-c5c84f348cd6";

export const useAuthStore = defineStore("auth", () => {
  const loaded = ref(false);
  const interval = ref<ReturnType<typeof setInterval> | null>(null);
  const keycloak = shallowRef<Keycloak | null>(null);

  const token = computed(() => `Bearer ${keycloak.value?.token}`);

  const idTokenParsed = computed(() => {
    return keycloak.value?.idTokenParsed as
      | GovRadarKeycloakTokenParsed
      | undefined;
  });

  const isOrgAdmin = computed(
    () =>
      userId.value &&
      (idTokenParsed.value?.org_group_info?.org_admins ?? []).includes(
        userId.value,
      ),
  );

  const orgId = computed<string | null>(
    () => idTokenParsed.value?.org_id ?? null,
  );

  const isGovRadarMember = computed<boolean>(
    () => orgId.value === govRadarOrgId,
  );

  const userId = computed<string | null>(
    () => idTokenParsed.value?.sub ?? null,
  );

  const licenses = computed(() => {
    const userLicenses: Record<UserLicenseType, boolean> = {
      ai_assistant_license: false,
      documents_designer_license: false,
      documents_writer_license: false,
      markets_license: false,
      tenders_license: false,
    };

    for (const license of idTokenParsed.value?.user_licenses ?? []) {
      userLicenses[license] = true;
    }

    return userLicenses;
  });

  function redirectToPasswordUpdatePage() {
    // redirect to password update account page
    keycloak.value?.login({ action: "UPDATE_PASSWORD" });
  }

  function login(newKeycloak: Keycloak) {
    keycloak.value = newKeycloak;
    // id of the alert that gets created if the token is about to expire
    let logoutAlertId: null | string = null;

    // check token every 10s
    interval.value = setInterval(() => {
      // refresh token
      keycloak.value
        ?.updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            // trigger keycloak ref if token was refreshed to update computed values
            triggerRef(keycloak);
            // remove alert
            if (logoutAlertId) {
              useAlertStore().removeAlert({ id: logoutAlertId });
              logoutAlertId = null;
            }
          }
        })
        .catch(() => {
          console.error("Token refresh failed");
          // issue a alert if token expires soon
          if (keycloak.value?.isTokenExpired(40) && logoutAlertId === null)
            logoutAlertId = useAlertStore().createAlert({
              type: "error",
              heading: "Authentifizierung konnte nicht erneuert werden",
              message:
                "Sollte das Problem weiterhin bestehen, werden Sie in 30 Sekunden ausgeloggt.",
            });
          // logout if token expired, should only be expired if the refresh failed 6 times
          if (keycloak.value?.isTokenExpired(10)) logout();
        });
    }, 10000);
  }

  const lastActivity = ref(Date.now());
  const inactivityTimeout = import.meta.env.VITE_AUTO_LOGOUT_TIMEOUT;
  const inactivityWarningThreshold = inactivityTimeout * 0.1;

  function logout(options?: KeycloakLogoutOptions) {
    // logout from keycloak and reset store values
    keycloak.value?.logout(options);
    keycloak.value = null;
    if (interval.value !== null) {
      clearInterval(interval.value);
      interval.value = null;
    }
  }

  return {
    loaded,
    token,
    idTokenParsed,
    isOrgAdmin,
    isGovRadarMember,
    orgId,
    userId,
    licenses,
    login,
    logout,
    lastActivity,
    inactivityTimeout,
    inactivityWarningThreshold,
    redirectToPasswordUpdatePage,
  };
});

<template>
  <div
    aria-live="assertive"
    role="alert"
    class="fixed inset-0 z-50 flex items-end justify-end px-4 py-6 top-12 pointer-events-none sm:p-6 sm:items-start"
  >
    <!-- Global notification live region, render this permanently at the end of the document -->
    <transition-group
      tag="div"
      enter-active-class="transition duration-300 ease-out transform "
      enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
      leave-active-class="absolute transition duration-200 ease-in"
      leave-from-class="scale-100 opacity-100"
      leave-to-class="scale-75 opacity-0"
      move-class="transition duration-300 ease"
      class="block w-full max-w-sm h-full"
    >
      <button
        v-if="alertStore.alerts.length > 2"
        class="w-full max-w-sm mb-4 p-2 bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden text-gray-500 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-2 focus-visible:bg-gray-100"
        @click="alertStore.alerts = []"
      >
        Alle {{ alertStore.alerts.length }} Meldungen schließen
      </button>
      <div
        v-for="alert in alertStore.alerts"
        :key="alert.id"
        class="w-full max-w-sm mb-4"
        aria-live="polite"
      >
        <Alert :alert="alert" />
      </div>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import Alert from "./AlertItem.vue";
import { useAlertStore } from "@/stores/alert";

const alertStore = useAlertStore();
</script>

import { createAndSetupAxios } from "@/helpers/setupAxios";
import { ProductVertical } from "@/types/vertical";
import { BaseCategory, CategoryIndex, CategoryKeyword } from "@/types/shopping";
import { Product } from "@/types/product";
import {
  SpecToSaveOut,
  SpecStatus,
  SpecSearchBrandResultOut,
} from "@/types/spec";
import { Group } from "@/types/group";
import { Option } from "@/types/option";
import { Configurator, ShoppingSearchParams } from "@/types/shoppingsearch";
import {
  CategorySearchOut,
  UniversalSearchResults,
} from "@/types/searchResult";
import { ProductSpec, KeywordSpec } from "@/types/criterion";

const $axios = createAndSetupAxios(import.meta.env.VITE_PRODUCTS_ROUTE);

const throwWhenAnyFalsy = (parameters: Record<string, unknown>) => {
  const keys = Object.keys(parameters);

  for (const key of keys) {
    if (!parameters[key]) {
      throw new Error(`(${keys.join(" and ")}) needs to be set.`);
    }
  }
};

interface GetSpecsResponse {
  specs: ProductSpec[];
}

class ProductsService {
  getVerticals(): Promise<ProductVertical[]> {
    return $axios.get("verticals/").then((response) => response.data);
  }

  getVertical(vertical: number): Promise<ProductVertical> {
    throwWhenAnyFalsy({ vertical });

    return $axios
      .get("verticals/")
      .then((response) =>
        response.data.find(
          (item: ProductVertical) => item.vertical_id === vertical,
        ),
      );
  }

  getCategories(vertical: number): Promise<CategoryIndex[]> {
    throwWhenAnyFalsy({ vertical });

    return $axios
      .get("categories/", {
        params: {
          vertical_id: vertical,
          api_version: 2,
          vertical,
        },
      })
      .then((response) =>
        response.data.sort((e1: CategoryIndex, e2: CategoryIndex) =>
          e1.category_name.localeCompare(e2.category_name),
        ),
      );
  }

  getCategory(vertical: number, category: string): Promise<CategoryIndex> {
    throwWhenAnyFalsy({ vertical, category });

    return $axios
      .get("categories/", {
        params: {
          vertical_id: vertical,
        },
      })
      .then((response) =>
        response.data.find(
          (item: CategoryIndex) => item.category_id === category,
        ),
      );
  }

  async getCategoryKeywords(
    vertical: number,
    category: string,
  ): Promise<CategoryKeyword[]> {
    throwWhenAnyFalsy({ vertical, category });

    return (
      await $axios.get("category_keywords/", {
        params: {
          vertical_id: vertical,
          category_id: category,
        },
      })
    ).data;
  }

  //not used anywhere right now and returning types not in documentation, unknown will need to be replaced when implemented
  getFacets(vertical: number, category: number): Promise<unknown> {
    throwWhenAnyFalsy({ vertical, category });

    return $axios
      .get("facets/", {
        params: {
          vertical_id: vertical,
          category_id: category,
        },
      })
      .then((response) => response.data);
  }

  //not used anywhere right now and returning types not in documentation, unknown will need to be replaced when implemented
  getIndex(vertical: number, category: string): Promise<unknown> {
    throwWhenAnyFalsy({ vertical, category });

    return $axios
      .get("index/", {
        params: {
          vertical_id: vertical,
          category_id: category,
        },
      })
      .then((response) => response.data);
  }

  getProduct(
    vertical: number,
    category: string,
    product: string,
  ): Promise<Product> {
    throwWhenAnyFalsy({ vertical, category, product });

    return $axios
      .get("product/", {
        params: {
          vertical_id: vertical,
          category_id: category,
          product: product,
        },
      })
      .then((response) => response.data);
  }

  getConfig(vertical: number, config: string): Promise<Product> {
    throwWhenAnyFalsy({ vertical, config });

    return $axios
      .get("configurator/", {
        params: {
          vertical_id: vertical,
          configurator_id: config,
        },
      })
      .then((response) => response.data);
  }

  async getSpecs(
    vertical: number,
    category: string,
    product: string,
    specs_search_id?: string,
    max_specs?: number,
  ): Promise<GetSpecsResponse> {
    throwWhenAnyFalsy({ vertical, category, product });

    const params = {
      vertical_id: vertical,
      category_id: category,
      product: product,
      specs_search_id: specs_search_id,
      max_specs: max_specs,
    };

    return {
      specs: (
        await $axios.get("specs/", {
          params,
        })
      ).data,
    };
  }

  async getConfigSpecs(
    vertical: number,
    category: string,
    configurator: string,
    product: string,
    specs_search_id?: string,
  ): Promise<GetSpecsResponse> {
    throwWhenAnyFalsy({ vertical, category, configurator, product });

    const params = {
      vertical_id: vertical,
      category_id: category,
      configurator_id: configurator,
      product: product,
      specs_search_id: specs_search_id,
    };

    return {
      specs: (
        await $axios.get("configurator_specs/", {
          params,
        })
      ).data,
    };
  }

  saveSpecs(
    specs: ProductSpec[],
    specs_search_id: string,
    vertical_id: number,
    category_id: string,
    product: string,
  ): Promise<SpecToSaveOut[]> {
    throwWhenAnyFalsy({
      specs,
      specs_search_id,
      product,
      vertical_id,
      category_id,
    });

    const data = {
      specs: specs,
      specs_search_id: specs_search_id,
      product_id: product,
      vertical_id: vertical_id,
      category_id: category_id,
    };

    return $axios
      .post("specs/status/store/", data)
      .then((response) => response.data);
  }

  getSpec(
    vertical: number,
    category: string,
    product: string,
    spec: ProductSpec,
  ): Promise<ProductSpec> {
    throwWhenAnyFalsy({ vertical, category, product, spec });

    return $axios
      .post("spec/", {
        vertical_id: vertical,
        category_id: category,
        product: product,
        spec: spec,
      })
      .then((response) => response.data);
  }

  getKeywordSpec(
    vertical: number,
    category: string,
    product: string,
    keyword_spec: KeywordSpec,
  ): Promise<KeywordSpec> {
    throwWhenAnyFalsy({ vertical, category, product, keyword_spec });

    return $axios
      .post("keyword_spec/", {
        vertical_id: vertical,
        category_id: category,
        product: product,
        keyword_spec: keyword_spec,
      })
      .then((response) => response.data);
  }

  getGroups(vertical: number, category: string): Promise<Group[]> {
    throwWhenAnyFalsy({ vertical, category });

    return $axios
      .get("groups/", {
        params: {
          vertical_id: vertical,
          category_id: category,
        },
      })
      .then((response) => response.data);
  }

  getStatus(
    vertical: number,
    category: string,
    product: string,
    specs: ProductSpec[],
    specs_search_id?: string,
  ): Promise<SpecStatus> {
    throwWhenAnyFalsy({ vertical, category, product, specs });

    const data = {
      vertical_id: vertical,
      category_id: category,
      product: product,
      specs: specs,
      specs_search_id: specs_search_id,
    };

    return $axios
      .post("specs/status/", {
        ...data,
      })
      .then((response) => response.data);
  }

  getStoredStatus(status_id: number): Promise<SpecSearchBrandResultOut[]> {
    throwWhenAnyFalsy({ status_id });

    const params = {
      status_id: status_id,
    };

    return $axios
      .get("specs/stored_status/", {
        params,
      })
      .then((response) => response.data);
  }

  //Option type not defined in docs, created type with the help of criterion.ts, but should double check when type is defined in docs
  getOptions(
    vertical: number,
    category: number,
    product: string,
    feature_id: string,
  ): Promise<Option> {
    throwWhenAnyFalsy({ vertical, category, product, feature_id });

    return $axios
      .get("options/", {
        params: {
          product: product,
          vertical_id: vertical,
          category_id: category,
          feature_id: feature_id,
        },
      })
      .then((response) => response.data);
  }

  //CategorySearchOut contains top_n_products which isnt defined in docs, defined the type by making some calls but should double check when type is defined in docs
  getCategoriesForSearchTerm(searchTerm: string): Promise<CategorySearchOut> {
    return $axios
      .post(
        "category_search/",
        {
          search_term: searchTerm,
        },
        { params: { api_version: 2 } },
      )
      .then((response) => response.data);
  }

  //selected items in shoppingsearchparams not defined in docs, set to any for now, need to change when type is defined in docs
  getShoppingResults(
    vertical: number,
    category: string,
    shopping_search_id: string,
    search_params: ShoppingSearchParams,
  ): Promise<ShoppingSearchParams> {
    throwWhenAnyFalsy({ vertical, category, search_params });

    return $axios
      .post("shopping_search/", {
        vertical_id: vertical,
        category_id: category,
        shopping_search_id: shopping_search_id,
        search_params: search_params,
      })
      .then((response) => response.data);
  }

  getConfigurators(
    vertical: number,
    category: string,
  ): Promise<Configurator[]> {
    throwWhenAnyFalsy({ vertical, category });

    return $axios
      .get("configurators_by_category/", {
        params: {
          vertical_id: vertical,
          category_id: category,
        },
      })
      .then((response) => response.data);
  }

  getAllSearchSpecs(
    vertical: number,
    category: string,
  ): Promise<ProductSpec[]> {
    throwWhenAnyFalsy({ vertical, category });

    const data = {
      vertical_id: vertical,
      category_id: category,
    };

    return $axios
      .post("specs/get_all_search_specs/", data)
      .then((response) => response.data);
  }

  //returning type not defined in docs, did some manual calls and type seems to be CategoryIndex, but should double check when type is defined in docs
  saveUnavailableSearch(
    vertical: number,
    category: string,
  ): Promise<CategoryIndex> {
    throwWhenAnyFalsy({ vertical, category });

    return $axios
      .post("unavailable_categories/", {
        vertical_id: vertical,
        category_id: category,
      })
      .then((response) => response.data);
  }

  requestCategory(category: BaseCategory): Promise<string> {
    return $axios
      .post("/empty_category_request/", category)
      .then((response) => response.data);
  }

  checkForExcludingRows(
    productSpecs: Product,
    specs: ProductSpec[],
  ): Promise<ProductSpec[]> {
    return $axios
      .post("specs/status/check_reference_product/", {
        product_specs: productSpecs,
        specs: specs,
      })
      .then((response) => response.data);
  }

  getAllSearchSpecsWithOptions(
    verticalId: string,
    categoryId: string,
    configId?: string,
  ): Promise<CategoryIndex[]> {
    return $axios
      .get(
        `specs/get_all_search_specs_with_options/${verticalId}/${categoryId}/`,
        {
          params: {
            configurator_id: configId,
          },
        },
      )
      .then((response) => response.data);
  }

  searchProductsFromAllCategories(
    searchTerm: string,
  ): Promise<UniversalSearchResults> {
    return $axios
      .get(`search_by_term/${searchTerm}/`)
      .then((response) => response.data);
  }
}

export default new ProductsService();

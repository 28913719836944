import { App } from "vue";
import matomo from "./tomato/plugin.js";
import { Router } from "vue-router";
import MatomoAnalytics from "./tomato/service.js";
import { injectionKey } from "./index.js";

export type AnalyticsPluginOptions = {
  router: Router;
  userId?: string;
  orgId?: string;
};

export default {
  install: (app: App, options: AnalyticsPluginOptions) => {
    app.use(matomo, options);
    app.provide(injectionKey, new MatomoAnalytics());
  },
};

export interface OrderItem {
  order_no: number;
}

interface OrderItemWithId extends OrderItem {
  id: string;
}

export const compareOrder = (a: OrderItem, b: OrderItem) =>
  a.order_no - b.order_no;

export function calculateOrderNumber(
  items: OrderItemWithId[],
  selectedItem: OrderItemWithId,
  beforeItem = false,
  afterItem = false,
) {
  const sortedItems = items.toSorted(compareOrder);
  const indexOfSelectedItem = sortedItems.findIndex(
    (item) => item.id === selectedItem?.id,
  );

  if (sortedItems.length === 0) {
    // If no item exists yet then start with order_no 0.
    return 0;
  } else if (!selectedItem) {
    // Default behaviour is to put newly created items on top if no item given to rank after.
    return sortedItems[0].order_no - 1;
  } else if (selectedItem && afterItem) {
    if (indexOfSelectedItem === sortedItems.length - 1) {
      return selectedItem.order_no + 1;
    } else {
      return (
        (sortedItems[indexOfSelectedItem + 1].order_no +
          selectedItem.order_no) /
        2
      );
    }
  } else if (selectedItem && beforeItem) {
    if (indexOfSelectedItem === 0) {
      return selectedItem.order_no - 1;
    } else {
      return (
        (sortedItems[indexOfSelectedItem - 1].order_no +
          selectedItem.order_no) /
        2
      );
    }
  }
}

export function calculateOrderNumberFromIndexWhenMoved(
  items: OrderItem[],
  oldIndex: number,
  newIndex: number,
) {
  const sortedItems = items.toSorted(compareOrder);
  const movedDown = oldIndex ? oldIndex - newIndex < 0 : 1;

  if (sortedItems.length === 0) {
    // If no item exists yet then start with order_no 0.
    return 0;
  } else if (newIndex === sortedItems.length - 1) {
    return sortedItems[newIndex].order_no + 1;
  } else if (newIndex === 0) {
    return sortedItems[0].order_no - 1;
  } else if (movedDown) {
    return (
      (sortedItems[newIndex].order_no + sortedItems[newIndex + 1].order_no) / 2
    );
  } else {
    return (
      (sortedItems[newIndex].order_no + sortedItems[newIndex - 1].order_no) / 2
    );
  }
}

export function calculateOrderNumberFromIndexWhenAdded(
  items: OrderItem[],
  newIndex = items.length,
) {
  const sortedItems = items.toSorted(compareOrder);

  if (sortedItems.length === 0) {
    // If no item exists yet then start with order_no 0.
    return 0;
  } else if (newIndex === sortedItems.length) {
    return sortedItems[newIndex - 1].order_no + 1;
  } else if (newIndex === 0) {
    return sortedItems[0].order_no - 1;
  } else {
    return (
      (sortedItems[newIndex].order_no + sortedItems[newIndex - 1].order_no) / 2
    );
  }
}

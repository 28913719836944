import { createAndSetupAxios } from "@/helpers/setupAxios";
import type { UiResource } from "@/stores/featureUIs";

const $axios = createAndSetupAxios(import.meta.env.VITE_CORE_ROUTE);

class CoreService {
  getFeatureFlags(): Promise<Record<string, boolean>> {
    return $axios
      .get("feature_toggles/")
      .then((response) => response.data.feature_toggles);
  }

  getStaticWebApps(): Promise<UiResource[]> {
    return $axios.get("feature_uis/").then((response) => response.data);
  }
}

export default new CoreService();

// TODO: Integrate this component into ButtonNormal
<script setup lang="ts">
import { Component as ComponentType, computed } from "vue";
import { ButtonSize, ButtonType } from "@govradar/vue-components/types";
import Loader from "./CircleSpinner.vue";
import { RouteLocationRaw } from "vue-router";

const props = withDefaults(
  defineProps<{
    to?: RouteLocationRaw;
    href?: string;
    type?: ButtonType;
    loading?: boolean;
    disabled?: boolean;
    size?: ButtonSize;
    iconSize?: ButtonSize;
    icon?: ComponentType;
    text?: string;
    round?: boolean;
  }>(),
  {
    to: "",
    href: "",
    type: "button",
    size: "lg",
    iconSize: "md",
    icon: undefined,
    text: undefined,
    round: false,
  },
);

const emit = defineEmits<{
  click: [event: MouseEvent];
}>();

const is = computed(() => {
  if (props.to) {
    return "router-link";
  }

  if (props.href) {
    return "a";
  }

  return "button";
});
</script>

<template>
  <component
    :is="is"
    :to="to"
    :href="href"
    :type="type"
    class="group relative inline-flex appearance-none items-center justify-center border border-transparent text-center transition hover:z-10 focus-visible:outline-none text-gray-500 hover:text-gray-700 focus-visible:ring-2 focus-visible:ring-gray-700 focus-visible:ring-offset-0 active:text-gray-700 hover:bg-gray-100"
    :class="{
      // Size
      'gap-2 p-1 text-base font-medium': size === 'lg',
      'gap-2 p-1 text-sm font-medium': size === 'sm',

      // Shape
      'rounded-full': round,
      'rounded-md': !round && ['lg', 'md'].includes(size),

      // Functional
      'pointer-events-none cursor-not-allowed opacity-60': loading || disabled,
    }"
    :disabled="loading || disabled"
    @click="(event: MouseEvent) => emit('click', event)"
  >
    <Loader v-show="loading" />

    <div class="flex items-center gap-2">
      <div
        v-if="$slots.icon || icon"
        class="transition text-gray-500 hover:text-gray-700 flex-shrink-0"
        :class="{
          // Size
          'h-7 w-7': iconSize === 'lg',
          'h-6 w-6': iconSize === 'md',
          'h-5 w-5': iconSize === 'sm',
        }"
      >
        <slot name="icon">
          <component :is="icon" v-if="icon" />
        </slot>
      </div>
      <slot>
        <span v-if="text" class="truncate">{{ text }}</span>
      </slot>
    </div>
  </component>
</template>

import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/anleitungen",
    name: "user-manual-overview",
    component: () => import("@/views/userManual/UserManualOverview.vue"),
    meta: {
      scrollToTop: true,
      disableSidebar: true,
      disableHeaderBarActions: true,
      disableStartBreadcrumb: true,
    },
  },
  {
    path: "/anleitungen/:id",
    name: "user-manual",
    component: () => import("@/views/userManual/UserManual.vue"),
    props: (route) => ({
      manualId: String(route.params.id),
    }),
    meta: {
      scrollToTop: true,
      disableSidebar: true,
      disableHeaderBarActions: true,
      disableStartBreadcrumb: true,
    },
  },
];

export default routes;

<template>
  <component :is="as" ref="triggerParent" v-bind="$attrs">
    <slot name="trigger" :show="show" :hide="hide" />
  </component>

  <Teleport to="body">
    <transition
      enter-active-class="transition-opacity"
      leave-active-class="transition-opacity"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="showTooltip"
        ref="tooltip"
        role="tooltip"
        :class="[
          'z-50 normal-case tracking-normal font-normal text-base max-w-md',
          { 'pointer-events-none': !props.copyFromTooltip },
        ]"
        :style="{
          ...floatingStyles,
          width,
        }"
        v-on="tooltipEventListeners"
      >
        <div class="bg-gray-700 font-bold text-white text-sm px-2 py-2 rounded">
          <slot />

          <div ref="tooltipArrow" class="absolute -z-1" :style="arrowStyle">
            <div class="bg-gray-700 w-3 h-3 rotate-45"></div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import { offset, type Placement } from "@floating-ui/core";
import { arrow, autoUpdate, flip, shift, useFloating } from "@floating-ui/vue";
import { computed, ref, watch, toRefs } from "vue";
import { syncRef, useVModel } from "@vueuse/core";

/* eslint-disable vue/no-unused-properties */
const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    as?: "div" | "span";
    disabled?: boolean;
    placement?: Placement;
    width?: string;
    copyFromTooltip?: boolean;
  }>(),
  {
    as: "div",
    disabled: false,
    placement: "top-start",
    width: "max-content",
    copyFromTooltip: false,
  },
);

const emit = defineEmits<{
  "update:modelValue": [value: boolean];
}>();

const { placement } = toRefs(props);

const trigger = ref<null | HTMLElement>(null);
const triggerParent = ref<null | HTMLElement>(null);
const tooltip = ref<null | HTMLElement>(null);
const tooltipArrow = ref<null | HTMLElement>(null);

const offsetAmount = 6;
const showTooltip = ref(false);

syncRef(showTooltip, useVModel(props, "modelValue", emit));

watch(triggerParent, (parent) => {
  for (const child of parent?.children ?? []) {
    if (child instanceof HTMLElement) {
      trigger.value = child;
      return;
    }
  }
});

const {
  floatingStyles,
  placement: floatingPlacement,
  middlewareData,
} = useFloating(trigger, tooltip, {
  placement: placement,
  middleware: [
    offset(offsetAmount),
    shift({ padding: 5 }),
    flip(),
    arrow({ element: tooltipArrow }),
  ],
  whileElementsMounted: autoUpdate,
});

const arrowX = computed(() => middlewareData.value.arrow?.x ?? null);
const arrowY = computed(() => middlewareData.value.arrow?.y ?? null);

// https://github.com/floating-ui/floating-ui/discussions/2103#discussioncomment-4633661
const arrowStyle = computed(() => {
  const side = floatingPlacement.value.split("-")[0];
  const staticSide = {
    top: "bottom",
    right: "left",
    bottom: "top",
    left: "right",
  }[side];

  return {
    left: arrowX.value != null ? `${arrowX.value}px` : "",
    top: arrowY.value != null ? `${arrowY.value}px` : "",
    [String(staticSide)]: `${offsetAmount / -2}px`,
  };
});

const show = () => {
  showTooltip.value = !props.disabled;
};

const hide = () => {
  showTooltip.value = false;
};

const tooltipEventListeners = computed(() => {
  if (props.copyFromTooltip) {
    return {
      mouseenter: show,
      mouseleave: hide,
    };
  }
  return {};
});

defineExpose({ hide });
</script>

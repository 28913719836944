<template>
  <button title="Abmelden" @click="logout()">
    <div class="relative">
      <div
        class="h-6 m-auto relative group bg-white text-gray-500 border-2 border-gray-500 focus-visible:ring-2 focus-visible:bg-gray-700 focus-visible:text-white rounded-full overflow-clip"
      >
        <span class="relative bottom-0.5 text-sm px-3">Abmelden</span>
      </div>
      <div
        class="h-6 absolute top-0 left-0 p-0.5 z-10 transition-all duration-500 bg-gray-500 hover:bg-gray-700 hover:border-gray-700 ease-linear group text-white border-none overflow-clip bg-clip-content hover:bg-clip-border"
        :style="{ width: fillPercentage + '%' }"
        :class="{
          'rounded-full': fillPercentage >= 90,
          'rounded-s-full': fillPercentage < 90,
          'opacity-0': fillPercentage < 9,
        }"
      >
        <span class="relative bottom-0.5 text-sm px-3">Abmelden</span>
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";

const authStore = useAuthStore();

const { inactivityTimeout, logout } = authStore;

const { lastActivity } = storeToRefs(authStore);

const inactiveTime = ref(0);

setInterval(updateInactivityProgress, 100);

function updateInactivityProgress() {
  inactiveTime.value = Date.now() - lastActivity.value;
}

const fillPercentage = computed(() => {
  const silentPeriod = inactivityTimeout * 0.1;
  if (inactiveTime.value < silentPeriod) {
    return 100;
  }
  const percentage = 100 - (inactiveTime.value / inactivityTimeout) * 100;
  return percentage;
});
</script>

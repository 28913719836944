import type { RouteRecordRaw } from "vue-router";

/* MARKETS (Referenzprodukte) */
const routes: Array<RouteRecordRaw> = [
  {
    path: "/produktmerkmale/suche/start",
    alias: "/produktportfolio",
    name: "criteria-search1",
    component: () => import("../views/spec/Search1Overview.vue"),
    meta: { scrollToTop: true },
  },
  {
    path: "/produktmerkmale/suche/kategorien/:vertical",
    name: "criteria-search2",
    component: () => import("../views/spec/Search2Categories.vue"),
    props: (route) => ({
      verticalId: Number(route.params.vertical),
    }),
    meta: { scrollToTop: true },
  },
  {
    path: "/produktmerkmale/suche/produkt/:vertical/:category/:tab",
    name: "criteria-search3",
    component: () => import("../views/spec/Search3Product.vue"),
    props: (route) => ({
      tab: Number(route.params.tab),
      verticalId: Number(route.params.vertical),
      categoryId: String(route.params.category),
    }),
    meta: { scrollToTop: true },
  },
  {
    path: "/produktmerkmale/suche/merkmale/:vertical/:category/:configurator/:product",
    name: "criteria-search4",
    component: () => import("../views/spec/Search4Specs.vue"),
    props: (route) => ({
      verticalId: Number(route.params.vertical),
      categoryId: String(route.params.category),
      configuratorId: String(route.params.configurator),
      productId: String(route.params.product),
    }),
    meta: { scrollToTop: true },
  },
  // old paths for redirect
  {
    path: "/produktmerkmale/suche/produkt/:vertical/:category",
    redirect: (to) => {
      return {
        name: "criteria-search3",
        params: {
          ...to.params,
          tab: "0",
        },
      };
    },
  },
  {
    path: "/produktmerkmale/suche/merkmale/:vertical/:category/:product",
    redirect: (to) => {
      return {
        name: "criteria-search4",
        params: {
          ...to.params,
          configurator: "null",
        },
      };
    },
  },
];

export default routes;

<template>
  <TransitionRoot :show="modelValue" as="template" :unmount="unmount">
    <Dialog
      as="div"
      :unmount="unmount"
      @close="emit('update:modelValue', false)"
    >
      <div class="fixed inset-0 z-40 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
            :unmount="unmount"
          >
            <DialogOverlay class="fixed inset-0 bg-gray-800 bg-opacity-30" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
            :unmount="unmount"
          >
            <div
              class="w-full my-8 text-left align-middle transition-all transform bg-white shadow-xl max-h-[80vh]"
              :class="{
                'inline-block overflow-y-auto': !flexContainer,
                'inline-flex flex-col': flexContainer,
                [width]: Boolean(width),
                'px-6 py-5': addPadding,
              }"
            >
              <slot name="title">
                <DialogTitle
                  v-if="!disableTitle"
                  as="h2"
                  class="pr-8 mb-5 text-lg font-medium leading-6 text-gray-900"
                >
                  {{ title }}
                </DialogTitle>
              </slot>
              <div v-if="flexContainer" class="shrink min-h-0 overflow-auto">
                <slot />
              </div>
              <slot v-else />
              <div
                v-if="$slots.actionButtons"
                class="flex justify-end gap-2 mt-2"
              >
                <slot name="actionButtons" />
              </div>
              <div
                v-if="!hideCloseButton"
                class="absolute top-0 right-0 block pt-3 pr-3"
              >
                <button
                  type="button"
                  tab-index="-1"
                  class="bg-white p-0.5 border-2 border-transparent rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 hover:border-gray-100 focus-visible:outline-none focus-visible:ring-0 focus-visible:bg-gray-100 focus-visible:border-gray-100"
                  data-testid="modal-free-action-close"
                  @click="emit('update:modelValue', false)"
                >
                  <span class="sr-only">Schließen</span>
                  <XMarkIcon class="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { watch } from "vue";

const props = withDefaults(
  defineProps<{
    unmount?: boolean;
    title?: string;
    flexContainer?: boolean;
    disableTitle?: boolean;
    modelValue?: boolean;
    width?: string;
    addPadding?: boolean;
    hideCloseButton?: boolean;
  }>(),
  {
    unmount: true,
    title: "",
    disableTitle: false,
    modelValue: false,
    width: "max-w-md",
    addPadding: true,
  },
);

watch(
  () => props.modelValue,
  (newvalue) => {
    if (newvalue) {
      emit("shown");
    }
  },
);

const emit = defineEmits<{
  (eventName: "update:modelValue", value: boolean): void;
  (eventName: "shown"): void;
}>();
</script>

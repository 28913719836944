import { defineStore } from "pinia";
import { ref } from "vue";

export const useSidebarStore = defineStore("sidebarStore", () => {
  const isOpen = ref<boolean>(true);

  function openSidebar() {
    isOpen.value = true;
  }

  function closeSidebar() {
    isOpen.value = false;
  }

  function toggleSidebar() {
    isOpen.value = !isOpen.value;
  }

  return {
    isOpen,
    openSidebar,
    closeSidebar,
    toggleSidebar,
  };
});

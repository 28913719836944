import createObjectURL from "./createObjectURL";
import { AxiosResponse } from "axios";

export default function downloadFile(
  response: AxiosResponse<Blob>,
  filename: string,
) {
  const fileUrl = createObjectURL(response.data);
  if (fileUrl === null) {
    console.error("Could not create an object url.");
    return;
  }
  const downloadLink = document.createElement("a");
  downloadLink.href = fileUrl;
  downloadLink.setAttribute("download", filename);
  document.body.appendChild(downloadLink);
  downloadLink.click();
}

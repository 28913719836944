<template>
  <nav
    class="sticky top-0 z-40 flex items-center bg-white border-b-2 shadow-sm"
    aria-label="Breadcrumb"
    role="navigation"
    data-testid="breadcrumbs"
  >
    <slot name="before" />

    <ol
      class="flex flex-1 items-center h-12 overflow-hidden px-4 w-auto lg:px-6 gap-x-4"
    >
      <li
        v-for="(page, index) in pages"
        :key="page.text"
        class="flex items-center shrink gap-x-2 min-w-[2rem] overflow-hidden p-0.5 -m-0.5"
        :class="{
          hidden: useMobileLayout && index !== Math.max(0, pages.length - 2),
        }"
      >
        <ChevronRightIcon
          v-if="index > 0 && !useMobileLayout"
          class="shrink-0 w-5 h-5 text-gray-500"
          aria-hidden="true"
        />
        <router-link
          :to="page.to"
          class="shrink px-2.5 py-1 text-base font-medium text-gray-500 rounded first:ml-0 hover:text-gray-700 focus-visible:outline-none focus-visible:ring-gray-400 focus-visible:ring-2 focus-visible:ring-offset-0 focus-visible:ring-offset-white hover:bg-gray-100 whitespace-nowrap flex overflow-hidden"
          :title="page.text"
        >
          <component
            :is="page.icon"
            v-if="page.icon"
            class="shrink-0 mr-2 mt-0.5 w-5 h-5 text-gray-500"
            aria-hidden="true"
          />
          <span class="truncate">
            {{ page.text }}
          </span>
        </router-link>
      </li>
    </ol>

    <!-- User Menu -->
    <ol
      v-if="!route.meta.disableHeaderBarActions"
      class="flex items-center h-12 ml-auto px-4 w-auto lg:px-6 gap-x-4"
    >
      <li class="flex gap-2">
        <PopoverMenu
          v-if="
            authStore.isGovRadarMember && featureStore.flags.deployment_info
          "
          :icon="InformationCircleIcon"
          aria-label="Deployment Info"
        >
          <p class="font-bold text-gray-900 text-lg pb-2">Deployment Info</p>

          <dl
            class="grid grid-cols-[auto_1fr] gap-x-4 text-sm text-gray-800 leading-relaxed"
          >
            <template
              v-for="deployInfo in deploymentInfo"
              :key="deployInfo.name"
            >
              <dt class="whitespace-nowrap font-semibold text-end">
                {{ deployInfo.name }}
              </dt>
              <dd class="break-all">{{ deployInfo.value }}</dd>
            </template>
          </dl>

          <p class="font-bold text-gray-900 text-lg pb-2 mt-2">
            Service Worker Info
          </p>

          <dl
            class="grid grid-cols-[auto_1fr] gap-x-4 text-sm text-gray-800 leading-relaxed"
          >
            <dt class="whitespace-nowrap font-semibold text-end">
              lastUpdateCheck
            </dt>
            <dd v-if="serviceWorkerStore.lastUpdateCheck" class="break-all">
              {{
                new Intl.DateTimeFormat("de-de", {
                  timeStyle: "medium",
                  dateStyle: "medium",
                }).format(serviceWorkerStore.lastUpdateCheck)
              }}
            </dd>
            <dd v-else class="break-all">never</dd>
            <dt class="whitespace-nowrap font-semibold text-end">
              needRefresh
            </dt>
            <dd class="break-all">{{ serviceWorkerStore.needRefresh }}</dd>
            <dt class="whitespace-nowrap font-semibold text-end">
              offlineReady
            </dt>
            <dd class="break-all">{{ serviceWorkerStore.offlineReady }}</dd>
            <dt class="whitespace-nowrap font-semibold text-end">updating</dt>
            <dd class="break-all">{{ serviceWorkerStore.updating }}</dd>
          </dl>
          <ButtonNormal
            kind="secondary"
            @click="serviceWorkerStore.checkForUpdates()"
          >
            Check for Updates
          </ButtonNormal>
          <ButtonNormal
            v-if="serviceWorkerStore.needRefresh"
            class="ml-2"
            kind="secondary"
            @click="serviceWorkerStore.updateServiceWorker()"
          >
            Update Service Worker
          </ButtonNormal>
        </PopoverMenu>
        <PopoverMenu
          v-if="authStore.isGovRadarMember && featureStore.flags.flags_menu"
          :badge-text="featureStore.changedFlagsCount"
          :show-badge="featureStore.changedFlagsCount > 0"
          :icon="FlagIcon"
          aria-label="Feature Flag Popover"
        >
          <SelectFeatureFlags />
        </PopoverMenu>
        <PopoverMenu
          v-if="
            authStore.isGovRadarMember && featureStore.flags.feature_UIs_menu
          "
          :badge-text="featureUiStore.featureUiList.length"
          :show-badge="true"
          :icon="BeakerIcon"
          aria-label="Feature UIs Popover"
        >
          <div v-if="featureUiStore.featureUiList.length" class="mb-2">
            <p class="font-bold text-gray-900 text-lg">Feature UIs</p>
            <ul>
              <li
                v-for="featureUI in featureUiStore.featureUiList"
                :key="featureUI.resource_name"
              >
                <a
                  :href="featureUI.app_url + route.fullPath"
                  class="hover:underline"
                >
                  {{ featureUI.resource_name }}
                </a>
              </li>
            </ul>
          </div>
          <div v-if="featureUiStore.otherUiList.length">
            <p class="font-bold text-gray-900 text-lg">Other UIs</p>
            <ul>
              <li
                v-for="otherUI in featureUiStore.otherUiList"
                :key="otherUI.resource_name"
              >
                <a
                  :href="otherUI.app_url + route.fullPath"
                  class="hover:underline"
                >
                  {{ otherUI.resource_name }}
                </a>
              </li>
            </ul>
          </div>
        </PopoverMenu>
        <UserManualPopover />
        <TooltipWrapper
          placement="bottom-end"
          description="Einstellungen"
          class="my-auto"
        >
          <NavBarButton
            :icon="UserCircleIcon"
            aria-label="Einstellungen"
            :to="{ name: 'user-settings-account' }"
          />
        </TooltipWrapper>
        <LogoutButton />
      </li>
    </ol>

    <slot name="after" />
  </nav>
</template>

<script lang="ts" setup>
import PopoverMenu from "@/components/PopoverMenu.vue";
import { useScreenHelpers } from "@/helpers/screensize";
import { useAuthStore } from "@/stores/auth";
import { useFeatureStore } from "@/stores/feature";
import { useFeatureUIsStore } from "@/stores/featureUIs";
import type { Breadcrumb } from "@/types/breadcrumb";
import SelectFeatureFlags from "@/views/user/SelectFeatureFlags.vue";
import {
  ChevronRightIcon,
  FlagIcon,
  InformationCircleIcon,
  BeakerIcon,
  UserCircleIcon,
} from "@heroicons/vue/24/solid";
import { ref } from "vue";
import jsonData from "../../deployment-info.json";
import { useServiceWorkerStore } from "@/stores/serviceWorker";
import { ButtonNormal } from "@govradar/vue-components";
import UserManualPopover from "@/views/userManual/partials/UserManualPopover.vue";
import { useRoute } from "vue-router";
import LogoutButton from "./LogoutButton.vue";
import NavBarButton from "./NavBarButton.vue";
import TooltipWrapper from "./TooltipWrapper.vue";

defineProps<{
  pages: Breadcrumb[];
}>();

const route = useRoute();

const authStore = useAuthStore();

const featureStore = useFeatureStore();

const featureUiStore = useFeatureUIsStore();

const serviceWorkerStore = useServiceWorkerStore();

const { useMobileLayout } = useScreenHelpers();

const deploymentInfo = ref(jsonData);
</script>

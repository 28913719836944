import Keycloak from "keycloak-js";
import { getKeycloakURL } from "./urls";

export default async function setupKeycloak() {
  const initOptions = {
    url: getKeycloakURL(),
    realm: "GovRadar-App",
    clientId: "Frontend",
  };

  const keycloak = new Keycloak(initOptions);

  // be careful to remove this return because it affects the await in main.ts
  await keycloak.init({
    onLoad: "login-required",
    checkLoginIframe: false,
  });

  return keycloak;
}

import {
  breakpointsTailwind,
  useBreakpoints,
  useWindowSize,
} from "@vueuse/core";

export const useScreenHelpers = () => {
  const { width: currentScreenWidth, height: currentScreenHeight } =
    useWindowSize();

  const breakpoints = useBreakpoints({
    ...breakpointsTailwind,
    xs: 450,
    "3xl": 1800,
  });

  const useMobileLayout = breakpoints.smaller("lg");

  return {
    currentScreenWidth,
    currentScreenHeight,
    breakpoints,
    useMobileLayout,
  };
};

import { defineStore } from "pinia";
import { ref } from "vue";
import { ReleaseAnnouncement } from "@/services/iam";
import { IAMService } from "@/services";

export const useReleaseAnnouncementStore = defineStore(
  "release-announcements",
  () => {
    const announcements = ref<ReleaseAnnouncement[]>([]);
    const mediaSources = ref<Record<string, string[]>>({});
    const loadingMedia = ref(false);
    const showAnnouncements = ref(false);

    function loadAnnouncements() {
      IAMService.getReleaseAnnouncements()
        .then((res) => {
          announcements.value = res;
          if (res.length > 0) {
            const timeSinceLastView = getTimeSinceLastAnnouncementsView();
            if (timeSinceLastView === null || timeSinceLastView > 43200000) {
              setLastAnnouncementsView();
              showAnnouncements.value = true;
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    function getTimeSinceLastAnnouncementsView() {
      const lastViewed = localStorage.getItem("last-announcements-view");
      if (lastViewed) {
        const date = new Date(lastViewed);
        return new Date().getTime() - date.getTime();
      } else {
        return null;
      }
    }

    function setLastAnnouncementsView() {
      localStorage.setItem("last-announcements-view", new Date().toISOString());
    }

    function loadMedia(announcementId: string) {
      if (mediaSources.value[announcementId]?.length > 0) {
        return;
      }
      const mediaIds = announcements.value.find(
        (a) => a.id === announcementId,
      )?.media_ids;
      if (!mediaIds) {
        return;
      }
      loadingMedia.value = true;
      const jobs = mediaIds.map((id) => IAMService.getMedia(id));
      Promise.all(jobs)
        .then((sources) => {
          mediaSources.value[announcementId] = sources;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          loadingMedia.value = false;
        });
    }

    function markAsViewed() {
      IAMService.updateLastViewed();
    }

    return {
      announcements,
      showAnnouncements,
      mediaSources,
      loadingMedia,

      loadAnnouncements,
      loadMedia,
      markAsViewed,
    };
  },
);

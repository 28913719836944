import { computed, ref, watch, WatchSource } from "vue";
import { Breadcrumb } from "@/types/breadcrumb";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { HomeIcon } from "@heroicons/vue/24/solid";

const breadcrumbs = ref<Breadcrumb[]>([]);

const startpage = {
  text: "Startseite",
  to: { name: "start" },
  icon: HomeIcon,
};

export default function useBreadcrumbs(
  newBreadcrumbs?: Breadcrumb[] | WatchSource<Breadcrumb[]>,
) {
  const route = useRoute();

  if (Array.isArray(newBreadcrumbs)) {
    breadcrumbs.value = newBreadcrumbs;
  } else if (newBreadcrumbs) {
    watch(
      newBreadcrumbs,
      (newValue) => {
        breadcrumbs.value = newValue;
      },
      { immediate: true },
    );
  }

  onBeforeRouteLeave(() => {
    breadcrumbs.value = [];
  });

  return computed(() => {
    if (route.meta.disableStartBreadcrumb) return breadcrumbs.value;
    else return [startpage, ...breadcrumbs.value];
  });
}

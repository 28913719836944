import { defineStore } from "pinia";
import { ref } from "vue";
import { Alert } from "@/types/alert";
import { v4 as uuidv4 } from "uuid";

export const useAlertStore = defineStore("alert", () => {
  const alerts = ref<Alert[]>([]);

  function resetAlerts() {
    alerts.value = [];
  }

  function createAlert(alert: Alert, delayToRemove?: number) {
    const id = uuidv4();
    alert["id"] = id;
    alerts.value.push(alert);

    if (delayToRemove) {
      setTimeout(() => {
        removeAlert(alert);
      }, delayToRemove);
    }

    return id;
  }

  function removeAlert(alert: { id?: string }) {
    const index = alerts.value.findIndex((item: Alert) => item.id === alert.id);
    if (index !== -1) alerts.value.splice(index, 1);
  }

  return {
    alerts,
    resetAlerts,
    removeAlert,
    createAlert,
  };
});

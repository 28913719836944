import { Directive } from "vue";
import svgString from "/svg/image_placeholder.svg?raw";

export const imageFallbackDirective: Directive<HTMLImageElement> = {
  mounted: (el) => {
    el.onerror = () => {
      el.src = "data:image/svg+xml," + svgString;
    };
  },
};

<template>
  <div
    role="alert"
    class="w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
    data-testid="alert-item"
    :data-test-type="alert.type"
  >
    <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <CheckCircleIcon
            v-if="alert.type === 'success'"
            class="h-6 w-6 text-green-400"
            aria-hidden="true"
          />
          <InformationCircleIcon
            v-if="alert.type === 'info'"
            class="h-6 w-6 text-blue-400"
            aria-hidden="true"
          />
          <ArrowPathIcon
            v-if="alert.type === 'update'"
            class="h-6 w-6 text-blue-400"
            aria-hidden="true"
          />
          <ExclamationCircleIcon
            v-if="alert.type === 'error'"
            class="h-6 w-6 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div class="ml-3 w-0 flex-1">
          <p
            v-if="alert.heading"
            class="text-sm font-medium text-gray-900 mb-1"
            data-testid="alert-item-heading"
          >
            {{ alert.heading }}
          </p>
          <p class="text-sm text-gray-600" data-testid="alert-item-message">
            {{ alert.message }}
          </p>
          <div v-if="alert.actions?.length" class="mt-2">
            <ButtonNormal
              v-for="action of alert.actions"
              :key="action.label"
              class="mr-2"
              size="sm"
              kind="secondary"
              :text="action.label"
              @click="action.handler"
            />
          </div>
        </div>
        <div class="ml-4 flex-shrink-0 flex">
          <button
            class="bg-white p-0.5 rounded-md inline-flex text-gray-400 hover:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:bg-gray-100 focus-visible:ring-gray-200"
            @click="alertStore.removeAlert(alert)"
          >
            <span class="sr-only">Close</span>
            <XMarkIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  CheckCircleIcon,
  InformationCircleIcon,
  ArrowPathIcon,
  ExclamationCircleIcon,
} from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/24/solid";
import { useAlertStore } from "@/stores/alert";
import { Alert } from "@/types/alert";
import { ButtonNormal } from "@govradar/vue-components";

defineProps<{
  alert: Alert;
}>();

const alertStore = useAlertStore();
</script>
